<template>
  <v-container fluid>
    <v-row>
      <v-col md="12" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">overview</div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="boxs">
          <div class="boxTitle">Total Hours Yesterday</div>
          <div class="boxNum">
            {{ yesterdayHours.total_hours_yesterday }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="boxs">
          <div class="boxTitle">Total Tasks Today</div>
          <div class="boxNum">
            {{ totalTasks.total_tasks }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="boxs">
          <div class="boxTitle">Break Time Today</div>
          <div class="boxNum">
            {{ breakTime.break_time_today }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" cols="12" class="pr-0">
        <v-row>
          <v-col md="6" cols="12" class="pr-0">
            <template>
              <div>
                <VueApexCharts
                  width="530"
                  type="area"
                  :options="chartOptions"
                  :series="series"
                />
              </div>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-row>
        <v-col md="12" cols="12" class="pl-0 align-center justify-center">
          <div class="sideBoxs">
            <div class="sideBoxTitle">Open Tasks</div>
            <div class="sideBoxNum">
              {{ totalTasks.open_task }}
            </div>
          </div>
        </v-col>
        <v-col md="12" cols="12" class="pl-0 align-center justify-center">
          <div class="sideBoxs">
            <div class="sideBoxTitle">Pending Tasks</div>
            <div class="sideBoxNum">
              {{ totalTasks.pending_tasks }}
            </div>
          </div>
        </v-col>
        <v-col md="12" cols="12" class="pl-0 align-center justify-center">
          <div class="sideBoxs">
            <div class="sideBoxTitle">Todo Tasks</div>
            <div class="sideBoxNum">
              {{ totalTasks.todo_tasks }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col md="6" cols="12" class="pr-0">
        <template>
          <div class="toolbar">
            <form class="conFormSubmit conSearchForm mt-5">
              <v-col cols="12" md="6">
                <label class="eachLabel">Year</label>
                <v-select
                  v-model="name"
                  outlined
                  :items="years"
                  item-text="name"
                  item-value="name"
                  placeholder="Year"
                  hide-details="true"
                  @change="getListData()"
                  :menu-props="{ bottom: true, offsetY: true }"
                  attach
                  clearable
                  append-icon="mdi-chevron-down"
                ></v-select>
              </v-col>
            </form>
          </div>
          <div>
            <VueApexCharts
              width="530"
              :options="columnOptions"
              :series="columnSeries1"
            />
          </div>
        </template>
      </v-col>
      <v-col md="6" cols="12">
        <div class="conOfTicketsTable pa-0">
          <div class="d-flex justify-space-between pa-3 pt-4">
            <div class="ticketsTitle">
              Tasks<br />
              <span class="groupSupport">Today</span>
            </div>
          </div>
          <template>
            <v-col cols="12" class="d-flex justify-space-between pa-3 pt-2">
              <div class="textOfTable d-flex align-center">Create new task</div>
              <div class="">
                <router-link :to="{ name: 'addTasks' }"
                  ><v-icon>mdi-plus-box</v-icon></router-link
                >
              </div>
            </v-col>
            <div v-if="totalTasks.tasks">
              <div v-for="(task, index) in totalTasks.tasks" :key="index">
                <div class="line" />
                <span class="conofindex">{{ index + 1 }} </span>
                <v-col cols="12" class="d-flex pa-3 pt-2">
                  <div class="taskTitle">Project Name :</div>
                  <div class="checkTextStyle">{{ task.project_name }}</div>
                </v-col>

                <v-col cols="12" class="d-flex pa-3 pt-2">
                  <div class="textOfTable d-flex align-center">
                    <div class="taskTitle">Task Name :</div>
                    <div class="checkTextStyle">
                      {{ task.name }}
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" class="d-flex pa-3 pt-2">
                  <div class="textOfTable d-flex align-center">
                    <div class="taskTitle">Description :</div>
                    <div class="checkTextStyle">{{ task.description }}</div>
                  </div>
                </v-col>
              </div>
            </div>
          </template>
        </div>
      </v-col>

      <v-row>
        <v-col cols="12">
          <section class="conOfProjects">
            <div class="conOfTable">
              <v-data-table
                :headers="headers"
                :items="listData"
                :items-per-page="5"
                hide-default-footer
              >
                <template v-slot:[`item.country_name`]="{ item }">
                  <router-link
                    :to="{
                      name: 'countryProjects',
                      params: { id: item.id },
                    }"
                  >
                    {{ item.country_name }}
                  </router-link>
                </template>
              </v-data-table>
            </div>
          </section>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      yesterdayHours: {},
      breakTime: {},
      totalTasks: [],

      pagination: {
        current_page: 1,
      },
      page: 1,
      name: "",
      years: [
        {
          year_id: 0,
          name: 2019,
        },
        {
          year_id: 1,
          name: 2020,
        },
        {
          year_id: 2,
          name: 2022,
        },
        {
          year_id: 3,
          name: 2023,
        },
      ],
      headers: [
        {
          text: "Country",
          value: "country_name",
        },
        {
          text: "Jan",
          value: "statistics.Jan",
        },
        {
          text: "Feb",
          value: "statistics.Feb",
        },
        {
          text: "Mar",
          value: "statistics.Mar",
        },
        {
          text: "May",
          value: "statistics.May",
        },
        {
          text: "Jun",
          value: "statistics.Jun",
        },
        {
          text: "Jul",
          value: "statistics.Jul",
        },
        {
          text: "Aug",
          value: "statistics.Aug",
        },
        {
          text: "Sep",
          value: "statistics.Sep",
        },
        {
          text: "Oct",
          value: "statistics.Oct",
        },
        {
          text: "Nov",
          value: "statistics.Nov",
        },
        {
          text: "Dec",
          value: "statistics.Dec",
        },
      ],
      listData: [],
      boxDetails: [
        {
          BoxTitle: "Your Total Hours Yesterday",
          BoxNum: 0,
        },

        {
          BoxTitle: "Task Open",
          BoxNum: 43,
        },
        {
          BoxTitle: "Task On hold",
          BoxNum: 64,
        },
      ],
      sideboxDetails: [
        {
          BoxTitle: "Your Total Hours Today",
          BoxNum: 0,
        },
        {
          BoxTitle: "Received",
          BoxNum: 426,
        },
        {
          BoxTitle: "Average first response time",
          BoxNum: "33m",
        },
      ],
      // series: [
      //   {
      //     name: "Today",
      //     data: [31, 40, 38, 51, 42, 29, 60],
      //   },
      //   {
      //     name: "Yesterday",
      //     data: [21, 32, 45, 42, 34, 52, 41],
      //   },
      // ],
      // chartOptions: {
      //   chart: {
      //     type: "bar",
      //     toolbar: {
      //       show: false,
      //     },
      //   },

      //   dataLabels: {
      //     enabled: false,
      //   },
      //   stroke: {
      //     curve: "smooth",
      //   },
      //   xaxis: {
      //     type: "datetime",
      //     weekcategories: ["current_week", "last_week"],
      //   },
      // },
      chartOptions: {
        chart: {
          id: "area-datetime",
          toolbar: {
            show: false,
          },
          height: 350,
          type: "line",
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },

        colors: ["#66C7F4", "#C5EDAC", "#99C2A2", "#f54444"],

        stroke: {
          width: [4, 4, 4, 4],
        },
        plotOptions: {
          bar: {
            columnWidth: "70%",
          },
        },
        tooltip: {
          shared: false,
          x: {
            show: false,
          },
          y: {
            show: true,
          },
        },
        xaxis: {
          categories: ["current week", "last week"],
        },
      },
      series: [],
      columnSeries1: [],
      monthData: [],
      columnOptions: {
        chart: {
          id: "area-datetime",
          toolbar: {
            show: false,
          },
          height: 350,
          type: "bar",
          stacked: false,
          zoom: {
            autoScaleYaxis: true,
          },
        },

        colors: ["#66C7F4", "#C5EDAC", "#99C2A2", "#f54444"],

        stroke: {
          width: [4, 4, 4, 4],
        },
        plotOptions: {
          bar: {
            columnWidth: "70%",
          },
        },
        tooltip: {
          shared: false,
          x: {
            show: false,
          },
          y: {
            show: true,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "April",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
      },
    };
  },
  computed: {},

  created() {
    this.getListData(this.pagination.current_page);
    this.getChartWeekData();
    this.getTotalTasks();
    this.getBreakTime();
    this.getYesterdayHours();
  },
  methods: {
    getListData(page) {
      (this.columnSeries1 = []),
        apiServices
          .get(`statistics/year?per_page=7&page=${page}&year=${this.name}`)
          .then((res) => {
            if (res) {
              this.listData = res.data;

              for (let i = 0; i < this.listData.length; i++) {
                const countryData = this.listData[i];
                this.monthData = countryData.country_name;

                const newData = [];
                for (let key in countryData.statistics) {
                  if (
                    key !== "country_name" &&
                    countryData.statistics[key] !== null
                  ) {
                    newData.push(parseFloat(countryData.statistics[key]));
                  }
                }
                this.columnSeries1.push({
                  name: countryData.country_name,
                  data: newData,
                });
              }
              // Update xaxis categories with the new data keys
              this.columnOptions.xaxis.categories = Object.keys(
                this.listData[0].statistics
              ).filter(
                (key) =>
                  key !== "country_name" &&
                  this.listData[0].statistics[key] !== null
              );
              console.log("columnOptions", this.columnOptions);
            }
          });
    },
    getChartWeekData() {
      apiServices.get(`weekly-statistics`).then((res) => {
        if (res) {
          this.ChartWeekData = res.data;
          console.log("ChartWeekData", this.ChartWeekData);
          for (let i = 0; i < this.ChartWeekData.length; i++) {
            const countryWeekData = this.ChartWeekData[i];
            this.monthWeekData = countryWeekData.country_name;
            console.log("monthWeekData", this.monthWeekData);
            const newWeekData = [];
            for (let key in countryWeekData.statistics) {
              if (
                key !== "country_name" &&
                countryWeekData.statistics[key] !== null
              ) {
                newWeekData.push(parseFloat(countryWeekData.statistics[key]));
              }
            }
            this.series.push({
              name: countryWeekData.country_name,
              data: newWeekData,
            });
          }
          // Update xaxis categories with the new data keys
          this.chartOptions.xaxis.weekcategories = Object.keys(
            this.ChartWeekData[0].statistics
          ).filter(
            (key) =>
              key !== "country_name" &&
              this.ChartWeekData[0].statistics[key] !== null
          );
          console.log("chartOptions", this.chartOptions);
        }
      });
    },
    getTotalTasks() {
      this.isLoading = true;
      apiServices.get(`todays-tasks`).then((res) => {
        if (res) {
          this.totalTasks = res.data;
          console.log("totalTasks", this.totalTasks);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    getBreakTime() {
      this.isLoading = true;
      apiServices.get(`todays-breaks`).then((res) => {
        if (res) {
          this.breakTime = res.data;
          console.log("breakTime", this.breakTime);
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    getYesterdayHours() {
      this.isLoading = true;
      apiServices.get(`total-hours`).then((res) => {
        if (res) {
          this.yesterdayHours = res.data;
          console.log("yesterdayHours", this.yesterdayHours);
          console.log("res.data", res.data);
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },

  watch: {},
};
</script>
<style lang="scss" scoped>
@import "./_overview.scss";
</style>
